import { useMemo } from 'react';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    DESIGN_GEOMETRY_SCHEMA,
    GTM_FOLDER_PREFIX,
    PARAMETERIZED_VOLUMES_NAME,
} from 'src/constants';
import {
    useTransformationManager,
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { clearParameterizedVolumes, setParameterizedVolumes } from 'src/store/project/projectSlice';
import {
    makeSelectIsObjectOutdatedOrMissing,
    selectProjectSettings,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector, useGetLatestState } from 'src/store/store';
import type { ObjectIdWithVersion } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';
import { assert } from 'src/utils/gtmAssert';

export function useParameterizedVolumesManager() {
    const dispatch = useAppDispatch();
    const { executeTransformation, transformationStatus: parameterizationStatus } =
        useTransformationManager();
    const projectSettings = useAppSelector(selectProjectSettings);
    assert(projectSettings !== undefined, 'Current project settings are not defined');
    const memoizedSelectIsObjectOutdatedOrMissing = useMemo(
        makeSelectIsObjectOutdatedOrMissing,
        [],
    );
    const getLatestState = useGetLatestState();

    async function computeParameterizedVolumes(aggregateGeometry: ObjectIdWithVersion) {
        await executeTransformation(
            GtmMeshTransformationAction.ParameterizeVolumes,
            ShouldRenderUpdatedObjects.No,
            ShouldRunDetectorsOnUpdatedObjects.No,
            [aggregateGeometry],
            {
                name: `${GTM_FOLDER_PREFIX}/${aggregateGeometry.id}/parameterizedVolumes`,
                distanceUnit: projectSettings!.units,
            },
            {
                createdObjectsHandler: (createdObjects) => {
                    dispatch(
                        setParameterizedVolumes(
                            decorateNewObject(
                                createdObjects[0],
                                PARAMETERIZED_VOLUMES_NAME,
                                DESIGN_GEOMETRY_SCHEMA,
                                false,
                            ),
                        ),
                    );
                },
                cancellationPredicate: () =>
                    getLatestState((state) =>
                        memoizedSelectIsObjectOutdatedOrMissing(state, aggregateGeometry),
                    ),
            },
        );
    }

    function resetParameterizedVolumes() {
        dispatch(clearParameterizedVolumes());
    }

    return {
        resetParameterizedVolumes,
        computeParameterizedVolumes,
        parameterizationStatus,
    };
}
