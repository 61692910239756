import { WDSThemeProvider } from '@local/web-design-system-2';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';

import { defaultAnalyticalModelSettings } from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    DEFAULT_SHAPEQUALITYWEIGHT,
    DEFAULT_TARGETH,
    DEFAULT_ISCLOSED,
    DEFAULT_LINEPROXIMITYDETECTION,
    DEFAULT_STRAINTOLERANCE,
    DEFAULT_INITIALCLEANUP,
    DEFAULT_OPTIMIZE,
} from 'src/apiClients/gtmCompute/gtmComputeApi.constants';
import type { GtmLocalRemeshParams } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { HelperAccordion } from 'src/components/HelperAccordion';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import type { GtmLocalRemeshSettings } from 'src/gtmProject';
import { useParameterizedVolumesManager } from 'src/hooks/modelling/useParameterizedVolumesManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import type { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { updateModelAtIndexForCurrentProject } from 'src/store/project/projectSlice';
import {
    selectCurrentAnalyticalModelSettings,
    selectCurrentModelSelectedObject,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    analyticalModelSettingsFromLocalRemeshSettings,
    initialLocalRemeshSettingsState,
    selectAllLocalRemeshSettings,
    selectPatchAngleTolerance,
    selectPatchAngleToleranceValid,
    selectRadius,
    selectRadiusValid,
} from 'src/store/ui/localRemeshSettings';
import {
    setAllLocalRemeshSettings,
    setMaxChordalError,
    setMaxChordalErrorValid,
    setPatchAngleTolerance,
    setPatchAngleToleranceValid,
    setRadius,
    setRadiusValid,
} from 'src/store/ui/localRemeshSettings/localRemeshSettingsSlice';
import { CANCEL_LABEL, DEFAULT_LABEL } from 'src/strings';
import { DEFAULT_PANEL_WIDTH, DEFAULT_SETTING_FIELD_WIDTH_PX } from 'src/styles';
import { stringRepresentsFiniteNumber } from 'src/utils/math';
import {
    MAX_CHORDAL_ERROR,
    MAX_CHORDAL_ERROR_INFO,
    PATCH_ANGLE_TOLERANCE,
    PATCH_ANGLE_TOLERANCE_INFO,
} from 'src/visualization/Toolbar/RemeshSettingsDialog.constants';

import {
    APPLY_LABEL,
    HELP_DEGEN_REMESHING_INFO,
    HELP_WHAT_IS_LOCAL_REMESHING,
    RADIUS,
    RADIUS_INFO,
    REMESH_LABEL,
} from './LocalRemeshSettingsDialog.constants';
import { useStyles } from './LocalRemeshSettingsDialog.styles';

// Similar to RemeshSettingsDialog

export function LocalRemeshSettingsDialog({
    modelIndex,
    anchorEl,
    triIndices,
    executeTransformation,
    onClose,
}: {
    modelIndex: number;
    anchorEl: HTMLElement | null;
    triIndices: number[];
    executeTransformation: ReturnType<typeof useTransformationManager>['executeTransformation'];
    onClose: () => void;
}) {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const dispatch = useAppDispatch();
    const selectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const currentAnalyticalModelSettings =
        useAppSelector(selectCurrentAnalyticalModelSettings) ?? defaultAnalyticalModelSettings;
    const remeshSettingsUiState = useAppSelector(selectAllLocalRemeshSettings);
    const { resetVolumes } = useVolumesManager();
    const { resetParameterizedVolumes } = useParameterizedVolumesManager();
    const { isAggregate } = useObjectManager();

    const open = Boolean(anchorEl);
    const id = open ? 'remesh-popover' : undefined;

    if (anchorEl === null) return null;

    const transform = (remeshSettings: GtmLocalRemeshSettings) => {
        if (!selectedObject) {
            return;
        }

        const params: GtmLocalRemeshParams = {
            patchAngleTolerance: remeshSettings.patchAngleTolerance,
            maxChordalError: remeshSettings.maxChordalError,
            radius: remeshSettings.radius,
            seedTriInds: triIndices,
            shapeQualityWeight: DEFAULT_SHAPEQUALITYWEIGHT,
            targetH: DEFAULT_TARGETH,
            isClosed: DEFAULT_ISCLOSED, // TODO: set from object once we have that information
            lineProximityDetection: DEFAULT_LINEPROXIMITYDETECTION,
            strainTolerance: DEFAULT_STRAINTOLERANCE,
            initialCleanup: DEFAULT_INITIALCLEANUP,
            optimize: DEFAULT_OPTIMIZE,
        };

        executeTransformation(
            GtmMeshTransformationAction.LocalRemesh,
            ShouldRenderUpdatedObjects.Yes,
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [selectedObject],
            params,
            {
                handleAdditionalSideEffects: () => {
                    if (isAggregate(selectedObject.id)) {
                        resetVolumes();
                        resetParameterizedVolumes();
                    }
                },
            },
        );
    };

    const onRemesh = () => {
        if (!selectedObject) {
            return;
        }

        const updatedAnalyticalModelSettings = analyticalModelSettingsFromLocalRemeshSettings(
            currentAnalyticalModelSettings,
            remeshSettingsUiState,
        );
        dispatch(
            updateModelAtIndexForCurrentProject([
                { analyticalModelSettings: updatedAnalyticalModelSettings },
                modelIndex,
            ]),
        );

        const { localRemeshSettings } = updatedAnalyticalModelSettings;
        transform(localRemeshSettings);
        onClose();
    };

    const DIALOG_WIDTH = DEFAULT_PANEL_WIDTH;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            anchorReference="anchorPosition"
            // Magic numbers to position the dialog in a reasonable spot to the left of the anchor
            anchorPosition={{
                top: anchorEl ? anchorEl.getBoundingClientRect().top - 128 : 0,
                left: anchorEl ? anchorEl.getBoundingClientRect().left - DIALOG_WIDTH - 32 : 0,
            }}
        >
            <Box sx={{ width: DIALOG_WIDTH }}>
                <WDSThemeProvider themeMode={appTheme}>
                    <Paper elevation={16}>
                        <DialogTitle>{REMESH_LABEL}</DialogTitle>
                        <Divider />
                        <HelperAccordion
                            sx={{ p: 2, pb: 0 }}
                            title={HELP_WHAT_IS_LOCAL_REMESHING}
                            detailedExplanation={HELP_DEGEN_REMESHING_INFO}
                        />
                        <RawRemeshSettings />
                        <Divider />
                        <ResetCancelApply onClose={onClose} onRemesh={onRemesh} />
                    </Paper>
                </WDSThemeProvider>
            </Box>
        </Popover>
    );
}

function ResetCancelApply({ onClose, onRemesh }: { onClose: () => void; onRemesh: () => void }) {
    const applyTrace = useTrace('remesh-settings');
    const settingsUiState = useAppSelector(selectAllLocalRemeshSettings);
    const dispatch = useAppDispatch();
    const onReset = () => {
        dispatch(setAllLocalRemeshSettings(initialLocalRemeshSettingsState));
    };
    const onCancel = () => {
        onClose();
    };

    const allValid =
        settingsUiState.maxChordalErrorValid &&
        settingsUiState.patchAngleToleranceValid &&
        settingsUiState.radiusValid;
    const isDisabled = !allValid;

    return (
        <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
                automation-id={applyTrace('reset-button')}
                variant="text"
                size="medium"
                color="secondary"
                onClick={onReset}
                sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
            >
                {DEFAULT_LABEL}
            </Button>
            <Button
                automation-id={applyTrace('cancel-button')}
                variant="text"
                size="medium"
                color="secondary"
                onClick={onCancel}
            >
                {CANCEL_LABEL}
            </Button>
            <Button
                automation-id={applyTrace('apply-button')}
                variant="text"
                size="medium"
                color="primary"
                onClick={onRemesh}
                disabled={isDisabled}
            >
                {APPLY_LABEL}
            </Button>
        </DialogActions>
    );
}

function RawRemeshSettings() {
    const { classes } = useStyles();

    return (
        <Stack direction="column" className={classes.settingsSection}>
            <PatchAngleToleranceInput />
            <MaxChordalErrorInput />
            <RadiusInput />
        </Stack>
    );
}

function PatchAngleToleranceInput() {
    const applyTrace = useTrace('patch-angle');
    const { classes } = useStyles();
    const patchAngleTolerance = useAppSelector(selectPatchAngleTolerance);
    const inputIsValid = useAppSelector(selectPatchAngleToleranceValid);
    const dispatch = useAppDispatch();

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setPatchAngleTolerance(event.target.value));
        let isValid = stringRepresentsFiniteNumber(event.target.value);
        if (isValid) {
            const val = Number(event.target.value);
            isValid = val > 0 && val <= 45;
        }
        dispatch(setPatchAngleToleranceValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {PATCH_ANGLE_TOLERANCE}
            </Typography>
            <TextField
                automation-id={applyTrace('input-box')}
                value={patchAngleTolerance}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
            />
            <Tooltip title={PATCH_ANGLE_TOLERANCE_INFO}>
                <IconButton size="small" className={classes.infoButton}>
                    <InfoOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function MaxChordalErrorInput() {
    const applyTrace = useTrace('chordal-error');
    const { classes } = useStyles();
    const maxChordalError = useAppSelector((state) => state.localRemeshSettings.maxChordalError);
    const inputIsValid = useAppSelector((state) => state.localRemeshSettings.maxChordalErrorValid);
    const dispatch = useAppDispatch();

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setMaxChordalError(event.target.value));
        const isValid = stringRepresentsFiniteNumber(event.target.value);
        dispatch(setMaxChordalErrorValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {MAX_CHORDAL_ERROR}
            </Typography>
            <TextField
                automation-id={applyTrace('input-box')}
                value={maxChordalError}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
            />
            <Tooltip title={MAX_CHORDAL_ERROR_INFO}>
                <IconButton size="small" className={classes.infoButton}>
                    <InfoOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function RadiusInput() {
    const applyTrace = useTrace('radius');
    const { classes } = useStyles();
    const radius = useAppSelector(selectRadius);
    const inputIsValid = useAppSelector(selectRadiusValid);
    const dispatch = useAppDispatch();

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setRadius(event.target.value));
        let isValid = stringRepresentsFiniteNumber(event.target.value);
        if (isValid) {
            const val = Number(event.target.value);
            isValid = val > 0;
        }
        dispatch(setRadiusValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {RADIUS}
            </Typography>
            <TextField
                automation-id={applyTrace('input-box')}
                value={radius}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
            />
            <Tooltip title={RADIUS_INFO}>
                <IconButton size="small" className={classes.infoButton}>
                    <InfoOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}
