import type { GtmProject } from 'src/gtmProject/Project.types';

export const DEFAULT_CURRENT_PROJECT_STATE: CurrentProjectState = {
    project: {} as GtmProject,
    selectedModelIndex: -1,
    selectedObjectIndex: -1,
    isAggregateObjectSelected: false,
};

export interface CurrentProjectState {
    project: GtmProject;
    selectedModelIndex: number;
    selectedObjectIndex: number;
    isAggregateObjectSelected: boolean;
}

export interface ProjectState {
    current: CurrentProjectState;
    currentProjectVersionId: string;
    isSyncingProject: boolean;
}

// Distinguish the role/provenance of the object that we are dealing with.
export enum GtmObjectType {
    Object,
    AggregateGeometry,
    VolumeShell,
}
