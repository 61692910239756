import { UpdaterChainBase } from 'src/gtmProject/loader/updaters/UpdaterChainBase';

/**
 * Concrete class to apply a chain of updates to project JSON files.
 * This class must be updated when the project schema changes, i.e. a new concrete updater class to
 * handle the change must be created and added to the chain in the `initialize` method.
 */
export class UpdaterChain extends UpdaterChainBase {
    public initialize(): void {
        this.updatersList = [];
        // Add updaters here when changing the project schema
        // Example:
        // this.updatersList.push(new Updater_1_0_0_to_2_0_0());
        // this.updatersList.push(new Updater_2_0_0_to_2_1_0());
        // this.updatersList.push(new Updater_2_1_0_to_3_0_0());
    }
}
