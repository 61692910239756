import type {
    GtmMeshDetectHolesParams,
    GtmMeshDetectSelfIntersectionsParams,
    GtmMeshRemoveDegenerateTriangleParams,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import type { GeoscienceObject, ObjectIdWithVersion } from 'src/types/core.types';

/**
 * Notes:
 * - Any changes to the project types will require one of two options:
 *   1. If we want to keep backwards compatibility with existing projects:
 *     - Create a new project schema file with a new version, see
 *       apps\geotechnical-modeler\public\schemas
 *     - Updating the `CURRENT_PROJECT_SCHEMA_VERSION` in Project.constants.ts to match the new
 *       schema version.
 *     - Adding a project updater to the `UpdaterChain` in project-loader.ts.
 *   2. If we don't care about backwards compatibility (only before limited availability):
 *     - Update the project schema file in apps\geotechnical-modeler\public\schemas
 * - A project assumes the user's current org+workspace combination.
 *
 *  To update the schema, run the `rushx gen:project-schema` script from within the geotechnical-modeler app
 *  Example: VERSION=2-0-0 rushx gen:project-schema
 *
 */

export interface GtmProjectInput extends GeoscienceObject {}

export interface GtmEvoOutputObject extends GeoscienceObject {}

export interface AggregatableObject extends GeoscienceObject {
    isAggregated: boolean;
}

export interface GtmPoint {
    x: number;
    y: number;
    z: number;
}

export interface GtmVector {
    x: number;
    y: number;
    z: number;
}

export interface GtmBounds {
    minPoint: GtmPoint;
    maxPoint: GtmPoint;
    rotationAngle: number;
}

export interface GtmProjectSettings {
    units: string;
}

export interface GtmModelSettings {
    backgroundColor: string;
    showXyzAxis: boolean;
}

export interface GtmRemeshSettings {
    patchAngleTolerance: number;
    maxChordalError: number;
    targetH: number;
    shapeQualityWeight: number;
}

export interface GtmLocalRemeshSettings {
    patchAngleTolerance: number;
    maxChordalError: number;
    radius: number;
}

export interface GtmAnalyticalModelSettings {
    degenerateTriangleSettings: GtmMeshRemoveDegenerateTriangleParams;
    holeSettings: GtmMeshDetectHolesParams;
    selfIntersectionSettings: GtmMeshDetectSelfIntersectionsParams;
    remeshSettings: GtmRemeshSettings;
    localRemeshSettings: GtmLocalRemeshSettings;
}

export const enum GtmModelType {
    Analytical = 'Analytical',
    CrossSection = 'CrossSection',
    ParametrizedGeometry = 'ParametrizedGeometry',
}

interface GtmModel {
    type: GtmModelType;
    id: string;
    name: string;
    settings: GtmModelSettings;
}

/**
 * These fields are used to visualize the cross-sections in the UI
 */
export interface CrossSection {
    origin: GtmPoint;
    direction: GtmVector;
}

export interface GtmAnalyticalModel extends GtmModel {
    // The bounds of the analytical model defines a boundaryId which
    // is used to build the path for the aggregate geometry.
    // Since each aggregate geometry is titled "Aggregate Geometry", this
    // allows us to have multiple aggregate geometries in the workspace
    // without conflict.
    bounds: GtmBounds;
    inputObjects: GtmProjectInput[];
    objects: AggregatableObject[];
    aggregateGeometry: GtmEvoOutputObject;
    analyticalModelSettings: GtmAnalyticalModelSettings;
    volumes: GtmEvoOutputObject[];
    /* `design-geometry` object reference with the parameterized volumes from the aggregate. */
    parameterizedVolumes?: GtmEvoOutputObject;
    crossSections: CrossSection[];
    /* Input parametric objects. */
    parametricGeometries: GtmEvoOutputObject[];
}

export interface GtmCrossSectionModel extends GtmModel {
    crossSection: CrossSection;
    sourceAggregateGeometry: GtmEvoOutputObject;
    crossSectionObject: ObjectIdWithVersion;
}

export interface GtmParametrizedGeometryModel extends GtmModel {
    parametrizedObject: GtmEvoOutputObject;
    volumes: GtmEvoOutputObject[];
}

export type GtmModelUnion =
    | GtmAnalyticalModel
    | GtmCrossSectionModel
    | GtmParametrizedGeometryModel;

export function isGtmAnalyticalModel(model?: GtmModel): model is GtmAnalyticalModel {
    return ((model ?? {}) as GtmAnalyticalModel).type === GtmModelType.Analytical;
}

export interface GtmHistoryOperation {
    description: string;
}

export interface GtmHistoryEntry {
    operation: GtmHistoryOperation;
    versionId: string;
}

export interface GtmHistory {
    /* last undo entry is the most recent change */
    undoEntries: GtmHistoryEntry[];
    /* last redo entry is the most recently undone action */
    redoEntries: GtmHistoryEntry[];
}

/**
 * JSON Schema version of the project file.
 * Follows SchemaVer convention, see https://docs.snowplow.io/docs/pipeline-components-and-applications/iglu/common-architecture/schemaver/
 */
export interface SchemaVersion {
    /** Used to represent breaking schema changes which will prevent interaction with any historical
     * data.
     * Example: adding/removing a **required** property to/from the JSON schema.
     * */
    model: number;
    /** Used to represent schema changes which **may** prevent interaction with some historical
     * data.
     * Example: removing an **optional** property from the JSON schema when `additionalProperties`
     * is `false`.
     * */
    revision: number;
    /** Used to represent schema changes that are compatible with all historical data.
     * Example: adding an optional property to the JSON schema.
     */
    addition: number;
}

export interface GtmProject {
    schemaVersion: SchemaVersion;
    name: string;
    inputObjects: GtmProjectInput[];
    projectDescription?: string;
    history: GtmHistory;
    models: GtmModelUnion[];
    settings: GtmProjectSettings;
}
