/* eslint no-param-reassign: ["error", { "props": false }] */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';

import type {
    GtmEvoOutputObject,
    GtmProject,
    GtmHistoryEntry,
    GtmProjectInput,
    AggregatableObject,
    GtmModelUnion,
    GtmProjectSettings,
} from 'src/gtmProject';
import type { VersionId } from 'src/types/core.types';

import type { CurrentProjectState, ProjectState } from './projectSlice.types';
import { getCurrentAnalyticalModel, getCurrentModel } from './projectSliceUtils';
// eslint-disable-next-line import/no-cycle
import { initialProjectState } from './selectors';

export const projectSlice = createSlice({
    name: 'project',
    initialState: initialProjectState,
    reducers: {
        setCurrentProject(
            projectState: ProjectState,
            { payload }: PayloadAction<Partial<CurrentProjectState>>,
        ) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: payload.project ?? ({} as GtmProject),
                },
            };
        },
        overwriteProject(
            projectState: ProjectState,
            { payload }: PayloadAction<Partial<CurrentProjectState>>,
        ) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: cloneDeep(payload.project) as GtmProject,
                },
            };
        },
        clearProject(_: ProjectState) {
            return initialProjectState;
        },
        setProjectName(projectState: ProjectState, { payload }: PayloadAction<string>) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: { ...projectState.current.project, name: payload },
                },
            };
        },
        setCurrentProjectVersionId(projectState: ProjectState, { payload }: PayloadAction<string>) {
            projectState.currentProjectVersionId = payload;
        },
        setCurrentModelName(projectState: ProjectState, { payload }: PayloadAction<string>) {
            getCurrentModel(projectState).name = payload;
        },
        setVolumes(projectState: ProjectState, { payload }: PayloadAction<GtmEvoOutputObject[]>) {
            getCurrentAnalyticalModel(projectState).volumes = payload;
        },
        clearVolumes(projectState: ProjectState) {
            getCurrentAnalyticalModel(projectState).volumes = [];
        },
        setIsAggregated(
            projectState: ProjectState,
            { payload: [objectIndex, isAggregated] }: PayloadAction<[number, boolean]>,
        ) {
            getCurrentAnalyticalModel(projectState).objects[objectIndex].isAggregated =
                isAggregated;
        },
        setVisible(
            projectState: ProjectState,
            { payload: [objectIndex, visible] }: PayloadAction<[number, boolean]>,
        ) {
            getCurrentAnalyticalModel(projectState).objects[objectIndex].visible = visible;
        },
        updateInputObject(
            projectState: ProjectState,
            { payload: [objectId, modifiedObject] }: PayloadAction<[string, GtmProjectInput]>,
        ) {
            const { project } = projectState.current;
            const objectIndex = project.inputObjects?.findIndex((object) => object.id === objectId);
            if (objectIndex !== undefined && objectIndex !== -1) {
                project.inputObjects![objectIndex] = modifiedObject;
            }
        },
        updateAnalyticalModelObject(
            projectState: ProjectState,
            { payload: [objectId, modifiedObject] }: PayloadAction<[string, AggregatableObject]>,
        ) {
            const selectedModel = getCurrentAnalyticalModel(projectState);
            const objectIndex = selectedModel.objects.findIndex((object) => object.id === objectId);
            if (objectIndex !== undefined && objectIndex !== -1) {
                selectedModel.objects[objectIndex] = modifiedObject;
            }
        },
        updateVolumeObject(
            projectState: ProjectState,
            { payload: [objectId, modifiedObject] }: PayloadAction<[string, GtmEvoOutputObject]>,
        ) {
            const selectedModel = getCurrentAnalyticalModel(projectState);
            if (!selectedModel.volumes) return;
            const objectIndex = selectedModel.volumes.findIndex((object) => object.id === objectId);
            if (objectIndex !== undefined && objectIndex !== -1) {
                selectedModel.volumes[objectIndex] = modifiedObject;
            }
        },
        updateAggregateGeometry(
            projectState: ProjectState,
            { payload: [modifiedObject] }: PayloadAction<[GtmEvoOutputObject]>,
        ) {
            const selectedModel = getCurrentAnalyticalModel(projectState);
            selectedModel.aggregateGeometry = modifiedObject;
        },
        setObjectVersion(
            projectState: ProjectState,
            { payload: [objectIndex, version] }: PayloadAction<[number, VersionId]>,
        ) {
            getCurrentAnalyticalModel(projectState).objects[objectIndex].version = version;
        },
        setVolumeVersion(
            projectState: ProjectState,
            { payload: [volumeIndex, version] }: PayloadAction<[number, VersionId]>,
        ) {
            getCurrentAnalyticalModel(projectState).volumes![volumeIndex].version = version;
        },
        setAggregateVersion(projectState: ProjectState, { payload }: PayloadAction<VersionId>) {
            getCurrentAnalyticalModel(projectState).aggregateGeometry!.version = payload;
        },
        removeObject(projectState: ProjectState, { payload: objectId }: PayloadAction<string>) {
            getCurrentAnalyticalModel(projectState).objects = getCurrentAnalyticalModel(
                projectState,
            ).objects.filter((obj) => obj.id !== objectId);
        },
        removeVolume(projectState: ProjectState, { payload: objectId }: PayloadAction<string>) {
            getCurrentAnalyticalModel(projectState).volumes = getCurrentAnalyticalModel(
                projectState,
            ).volumes?.filter((vol) => vol.id !== objectId);
        },
        addObject(
            projectState: ProjectState,
            { payload: object }: PayloadAction<AggregatableObject>,
        ) {
            getCurrentAnalyticalModel(projectState).objects.push(object);
        },
        addVolume(
            projectState: ProjectState,
            { payload: object }: PayloadAction<GtmEvoOutputObject>,
        ) {
            if (getCurrentAnalyticalModel(projectState).volumes) {
                getCurrentAnalyticalModel(projectState).volumes!.push(object);
            } else {
                getCurrentAnalyticalModel(projectState).volumes = [object];
            }
        },
        setParametricGeometries(
            projectState: ProjectState,
            { payload: parametricGeometries }: PayloadAction<GtmEvoOutputObject[]>,
        ) {
            getCurrentAnalyticalModel(projectState).parametricGeometries = parametricGeometries;
        },
        addOrReplaceParametricGeometryEntryByName(
            projectState: ProjectState,
            { payload: newParametricGeometry }: PayloadAction<GtmEvoOutputObject>,
        ) {
            const parametricGeometries =
                getCurrentAnalyticalModel(projectState).parametricGeometries ?? [];
            const index = parametricGeometries.findIndex(
                (parametricGeometry) => parametricGeometry.name === newParametricGeometry.name,
            );

            if (index === -1) {
                parametricGeometries.push(newParametricGeometry);
            } else {
                parametricGeometries[index] = newParametricGeometry;
            }

            getCurrentAnalyticalModel(projectState).parametricGeometries = parametricGeometries;
        },
        setAggregate(
            projectState: ProjectState,
            { payload: object }: PayloadAction<GtmEvoOutputObject>,
        ) {
            getCurrentAnalyticalModel(projectState).aggregateGeometry = object;
        },
        setParameterizedVolumes(
            projectState: ProjectState,
            { payload: object }: PayloadAction<GtmEvoOutputObject>,
        ) {
            getCurrentAnalyticalModel(projectState).parameterizedVolumes = object;
        },
        clearParameterizedVolumes(projectState: ProjectState) {
            getCurrentAnalyticalModel(projectState).parameterizedVolumes = undefined;
        },
        appendHistoryEntry(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmHistoryEntry>,
        ) {
            projectState.current.project.history.undoEntries.push(payload);
        },
        clearRedoEntries(projectState) {
            projectState.current.project.history.redoEntries = [];
        },
        setSelectedModelIndex(projectState: ProjectState, { payload }: PayloadAction<number>) {
            projectState.current.selectedModelIndex = payload;
        },
        deselectSelectedModel(projectState: ProjectState) {
            projectState.current.selectedModelIndex = -1;
        },
        setSelectedObjectIndex(projectState: ProjectState, { payload }: PayloadAction<number>) {
            projectState.current.selectedObjectIndex = payload;
            projectState.current.isAggregateObjectSelected = false;
        },
        deselectSelectedObject(projectState: ProjectState) {
            projectState.current.selectedObjectIndex = -1;
            projectState.current.isAggregateObjectSelected = false;
        },
        updateModelAtIndexForCurrentProject(
            projectState: ProjectState,
            { payload: [newEntries, index] }: PayloadAction<[Partial<GtmModelUnion>, number]>,
        ) {
            projectState.current.project.models[index] = {
                ...projectState.current.project.models[index],
                ...newEntries,
            };
        },
        deleteModelInCurrentProject(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmModelUnion>,
        ) {
            projectState.current.project.models = projectState.current.project.models.filter(
                (model) => model.id !== payload.id,
            );
        },
        addModelToCurrentProject(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmModelUnion>,
        ) {
            projectState.current.project.models.push(payload);
        },
        addInputObjectsToProject(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmProjectInput[]>,
        ) {
            projectState.current.project.inputObjects?.push(...payload);
        },
        removeInputObjectsFromProject(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmProjectInput>,
        ) {
            projectState.current.project.inputObjects =
                projectState.current.project.inputObjects?.filter((obj) => obj.id !== payload.id);
        },
        setCurrentModelAggregateObjectAsSelected(projectState: ProjectState) {
            projectState.current.isAggregateObjectSelected = true;
            projectState.current.selectedObjectIndex = -1;
        },
        startProjectSync(projectState: ProjectState) {
            projectState.isSyncingProject = true;
        },
        endProjectSync(projectState: ProjectState) {
            projectState.isSyncingProject = false;
        },
        disaggregateAllObjects(projectState: ProjectState) {
            const { objects } = getCurrentAnalyticalModel(projectState);
            objects.forEach((object) => {
                object.isAggregated = false;
            });
        },
        updateProjectSettings(
            projectState: ProjectState,
            { payload: newSettings }: PayloadAction<Partial<GtmProjectSettings>>,
        ) {
            projectState.current.project.settings = {
                ...projectState.current.project.settings,
                ...newSettings,
            };
        },
    },
});

export const {
    setCurrentProject,
    overwriteProject,
    clearProject,
    setProjectName,
    setCurrentProjectVersionId,
    setVolumes,
    clearVolumes,
    setIsAggregated,
    setVisible,
    updateInputObject,
    updateAnalyticalModelObject,
    updateVolumeObject,
    updateAggregateGeometry,
    setSelectedModelIndex,
    deselectSelectedModel,
    setSelectedObjectIndex,
    deselectSelectedObject,
    appendHistoryEntry,
    clearRedoEntries,
    setObjectVersion,
    setVolumeVersion,
    setAggregateVersion,
    removeObject,
    addVolume,
    setParametricGeometries,
    addOrReplaceParametricGeometryEntryByName,
    setParameterizedVolumes,
    clearParameterizedVolumes,
    setAggregate,
    addObject,
    removeVolume,
    updateModelAtIndexForCurrentProject,
    deleteModelInCurrentProject,
    addModelToCurrentProject,
    addInputObjectsToProject,
    removeInputObjectsFromProject,
    setCurrentModelAggregateObjectAsSelected,
    setCurrentModelName,
    startProjectSync,
    endProjectSync,
    disaggregateAllObjects,
    updateProjectSettings,
} = projectSlice.actions;
