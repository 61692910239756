import type { GtmAnalyticalModel, GtmModelUnion } from 'src/gtmProject';
import { isGtmAnalyticalModel } from 'src/gtmProject';
import { assert } from 'src/utils/gtmAssert';

import type { ProjectState } from './projectSlice.types';

function getCurrentModelIfSelected(projectState: ProjectState): GtmModelUnion | undefined {
    return projectState.current.project?.models?.[projectState.current.selectedModelIndex];
}

export function getCurrentModel(projectState: ProjectState): GtmModelUnion {
    const currentModel = getCurrentModelIfSelected(projectState);
    if (currentModel) {
        return currentModel;
    }
    assert(false, 'Trying to select "current" model, but no model is selected.');
    return {} as GtmModelUnion;
}

export function getCurrentModelIfAnalytical(
    projectState: ProjectState,
): GtmAnalyticalModel | undefined {
    const currentModel = getCurrentModelIfSelected(projectState);
    if (currentModel !== undefined && isGtmAnalyticalModel(currentModel)) {
        return currentModel as GtmAnalyticalModel;
    }
    return undefined;
}

export function getCurrentAnalyticalModel(projectState: ProjectState) {
    const currentModel = getCurrentModelIfAnalytical(projectState);
    if (currentModel) {
        return currentModel;
    }
    assert(false, 'Current model is not an analytical model');
    return {} as GtmAnalyticalModel;
}
