import type {
    GtmAnalyticalModel,
    GtmAnalyticalModelSettings,
    GtmModelUnion,
    GtmProject,
} from 'src/gtmProject/Project.types';
import { isGtmAnalyticalModel } from 'src/gtmProject/Project.types';
import { useSceneObjectDataManager } from 'src/hooks';
import { useDefectsLoadingManager } from 'src/hooks/defects/useDefectsLoadingManager';
import { useEvoContextManager } from 'src/hooks/evoContext/useEvoContextManager';
import { useProjectManager } from 'src/hooks/project';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { clearIssues, removeIssuesForObject } from 'src/store/issues/issuesSlice';
import { setSelectedModelIndex, clearProject } from 'src/store/project/projectSlice';
import { selectCurrentProjectModels } from 'src/store/project/selectors';
import { useAppSelector, useAppDispatch } from 'src/store/store';
import type { ObjectIdWithVersion } from 'src/types/core.types';

// Purpose: Other managers are meant to encapsulate logic for particular logical groups
//          of actions (e.g. project actions, visualization actions, detection actions, etc.).
//          Those actions are typically related to a single slice of our store. This manager
//          is meant to encapsulate logic that involves multiple slices, but are common operations.
export function useConglomerateActionManager() {
    const dispatch = useAppDispatch();
    const models = useAppSelector(selectCurrentProjectModels);

    const { renderAndRunDetectorsForModel, renderObject, runAllDetectorsOnObject } =
        useModelLoader();
    const { clearVisualizationAndIssues, clearVisualizationAndIssuesForObject } = useStoreCleaner();
    const { findObjectAndDelete } = useObjectManager();
    const { loadProject } = useProjectManager();
    const { loadWorkspaceTriangleMeshes, loadProjectFiles } = useEvoContextManager();

    async function swapProject(projectName: string) {
        clearVisualizationAndIssues();
        const project = await loadProject(projectName);
        if (project) {
            renderInputObjects(project);
        }

        return project;
    }

    function renderInputObjects(project: GtmProject) {
        project.inputObjects.forEach((object) => {
            if (object.visible) {
                renderObject(object);
            }
        });
    }

    function refreshVisualizationForProject(project: GtmProject) {
        clearVisualizationAndIssues();
        renderInputObjects(project);
    }

    async function swapWorkspace() {
        clearVisualizationAndIssues();
        dispatch(clearProject());

        const gtmProjectFiles = await loadProjectFiles();
        loadWorkspaceTriangleMeshes();

        return gtmProjectFiles;
    }

    async function refreshVisualizationAndIssues(model: GtmModelUnion) {
        clearVisualizationAndIssues();
        await renderAndRunDetectorsForModel(model);
    }

    async function switchCurrentModel(index: number) {
        dispatch(setSelectedModelIndex(index));
        await refreshVisualizationAndIssues(models[index]);
    }

    function removeObject(object: ObjectIdWithVersion) {
        findObjectAndDelete(object.id);
        clearVisualizationAndIssuesForObject(object);
    }

    function renderParametricObjects(model: GtmAnalyticalModel) {
        model.volumes.forEach((volume) => {
            if (volume.visible) {
                renderObject(volume);
            }
        });
    }

    function renderTriangulatedObjects(model: GtmAnalyticalModel) {
        model.objects.forEach((object) => {
            if (object.visible) {
                renderObject(object);
            }
        });
        if (model.aggregateGeometry.visible) {
            renderObject(model.aggregateGeometry);
        }
    }

    function runDetectorsForModel(model: GtmAnalyticalModel) {
        model.objects.forEach((object) => {
            runAllDetectorsOnObject(object, model.analyticalModelSettings);
        });
        runAllDetectorsOnObject(model.aggregateGeometry, model.analyticalModelSettings);
    }

    return {
        swapProject,
        swapWorkspace,
        refreshVisualizationAndIssues,
        switchCurrentModel,
        removeObject,
        renderObject,
        runAllDetectorsOnObject,
        clearVisualizationAndIssues,
        clearVisualizationAndIssuesForObject,
        refreshVisualizationForProject,
        renderParametricObjects,
        renderTriangulatedObjects,
        runDetectorsForModel,
    };
}

function useModelLoader() {
    const { loadGtmObject } = useSceneObjectDataManager();
    const { runAllDetectors } = useDefectsLoadingManager();

    const renderObject = (object: ObjectIdWithVersion) => loadGtmObject(object.id, object.version);
    const runAllDetectorsOnObject = (
        object: ObjectIdWithVersion,
        analyticalModelSettings: GtmAnalyticalModelSettings,
    ) => runAllDetectors(object, analyticalModelSettings);

    function renderAndRunDetectorsForModel(model: GtmModelUnion) {
        const promises: Promise<void>[] = [];

        if (isGtmAnalyticalModel(model)) {
            model.objects.forEach((object) => {
                if (object.visible) promises.push(renderObject(object));
                // We explicitly pass the detection settings because currently we cannot reliably obtain them where the
                // detectors are actually invoked.
                promises.push(runAllDetectorsOnObject(object, model.analyticalModelSettings));
            });
            if (model.aggregateGeometry.visible) {
                promises.push(renderObject(model.aggregateGeometry));
            }

            promises.push(
                runAllDetectorsOnObject(model.aggregateGeometry, model.analyticalModelSettings),
            );
        }

        return Promise.all(promises);
    }

    return {
        renderObject,
        runAllDetectorsOnObject,
        renderAndRunDetectorsForModel,
    };
}

function useStoreCleaner() {
    const { clearGtmObjectsAndScene, removeGtmObject } = useSceneObjectDataManager();
    const dispatch = useAppDispatch();

    return {
        clearVisualizationAndIssues: () => {
            clearGtmObjectsAndScene();
            dispatch(clearIssues());
        },
        clearVisualizationAndIssuesForObject: (object: ObjectIdWithVersion) => {
            removeGtmObject(object.id);
            dispatch(removeIssuesForObject(object.id));
        },
    };
}
