import { useTrace } from '@local/web-design-system-2';
import { useBaseXyz } from '@local/webviz/dist/context';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/MoreVert';
import TuneOutlined from '@mui/icons-material/TuneOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, MouseEvent } from 'react';
import { type KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { MeshIcon } from 'src/assets/MeshIcon';
import { ParametricIcon } from 'src/assets/ParametricIcon';
import { SidebarLeft } from 'src/assets/SidebarLeft';
import { SidebarRight } from 'src/assets/SidebarRight';
import { TriangulatedIcon } from 'src/assets/TriangulatedIcon';
import { VolumeIcon } from 'src/assets/VolumeIcon';
import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import type {
    AggregatableObject,
    GtmAnalyticalModel,
    GtmEvoOutputObject,
    GtmModelUnion,
    GtmProjectInput,
} from 'src/gtmProject';
import { isGtmAnalyticalModel } from 'src/gtmProject';
import { useSceneObjectDataManager, useSceneObjectSelectionManager } from 'src/hooks';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { useParameterizedVolumesManager } from 'src/hooks/modelling/useParameterizedVolumesManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { skipHistoryEntry, useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { TransformationStatus } from 'src/hooks/transformation/useTransformationManager';
import { selectDoesCurrentModelObjectHaveIssues } from 'src/store/issues/selectors';
import {
    deleteModelInCurrentProject,
    deselectSelectedObject,
    setCurrentModelAggregateObjectAsSelected,
    setCurrentModelName,
    setSelectedObjectIndex,
    updateAnalyticalModelObject,
} from 'src/store/project/projectSlice';
import {
    selectCurrentAggregateGeometry,
    selectCurrentAnalyticalModelSettings,
    selectCurrentModel,
    selectCurrentModelObjects,
    selectCurrentModelSelectedObject,
    selectCurrentProjectData,
    selectCurrentProjectModelNames,
    selectCurrentProjectParameterizedVolumes,
    selectCurrentProjectVolumes,
    selectSelectedModelIndex,
    selectSelectedObjectIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    detectionSettingsFromAnalyticalModelSettings,
    setAllDetectionSettings,
} from 'src/store/ui/detectionSettings';
import {
    closeDetectionSettingsDialog,
    ModelViewTabSelection,
    openDetectionSettingsDialog,
    selectModelViewTabSelection,
    selectShouldMinimizeProjectPanel,
    selectShouldOpenDetectionSettingsDialog,
    setModelViewTabSelection,
    setShouldShowProjectPanel,
    toggleProjectPanelMinimize,
} from 'src/store/ui/projectPanel';
import { setShouldShowPublishPanel } from 'src/store/ui/publishPanel';
import { sceneObjectById } from 'src/store/visualization/selectors';
import { DEFAULT_LIST_MAX_HEIGHT } from 'src/styles';
import { fileNameExtensionRemover } from 'src/utils';
import { DetectionSettingsPanel } from 'src/visualization/DetectionSettingsPanel/DetectionSettingsPanel';
import { DETECTION_SETTINGS_PANEL_TITLE } from 'src/visualization/DetectionSettingsPanel/DetectionSettingsPanel.constants';
import { DeleteDialog } from 'src/visualization/ProjectPanel/components/DeleteDialog';
import {
    HideShowButtons,
    ObjectType,
} from 'src/visualization/ProjectPanel/components/HideShowButtons';
import { AggregateControl } from 'src/visualization/ProjectPanel/components/ModelView/AggregateControl';
import { ResetAggregateControl } from 'src/visualization/ProjectPanel/components/ModelView/ResetAggregateControl';
import { PanelItemMenu } from 'src/visualization/ProjectPanel/components/PanelItemMenu';
import { useGtmNavigator } from 'src/visualization/ProjectPanel/components/useGtmNavigator';
import {
    DELETE_MODEL_TITLE,
    getDeleteModelDescription,
    getDeleteModelMessage,
    getParametricObjectsTabLabel,
    getRenameModelDescription,
    getTriangulatedObjectsTabLabel,
    INVALID_NEW_MODEL_NAME_MESSAGE,
    OBJECTS_LABEL,
    PUBLISH_TO_EVO_LABEL,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export const ModelView = () => {
    const shouldMinimizeProjectPanel = useAppSelector(selectShouldMinimizeProjectPanel);

    return (
        <>
            <ModelTitle />
            {!shouldMinimizeProjectPanel && <ModelPanel />}
        </>
    );
};

const ModelTitle = () => {
    const applyTrace = useTrace('model-title');
    const dispatch = useAppDispatch();
    const shouldMinimizeProjectPanel = useAppSelector(selectShouldMinimizeProjectPanel);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const currentModel = useAppSelector(selectCurrentModel);
    const currentProjectModelNames = useAppSelector(selectCurrentProjectModelNames);
    const [modelToDelete, setModelToDelete] = useState<GtmModelUnion | null>(null);
    const { syncProject } = useProjectSynchronizer();
    const { navigateToModelUrl } = useGtmNavigator();
    const [isRenaming, setIsRenaming] = useState(false);
    const [newName, setNewName] = useState('');
    const isNewNameInvalid = useMemo(() => {
        let modelNames = currentProjectModelNames;
        if (isRenaming) {
            modelNames = modelNames.filter((name) => name !== currentModel?.name);
        }
        return modelNames.includes(newName.trim());
    }, [currentProjectModelNames, newName, isRenaming]);
    const shouldDisableRenameConfirm = isNewNameInvalid || newName.trim() === '';
    const modelSettings = useAppSelector(selectCurrentAnalyticalModelSettings);
    const modelIndex = useAppSelector(selectSelectedModelIndex);
    const currentProject = useAppSelector(selectCurrentProjectData);
    const shouldOpenDetectionSettingsDialog = useAppSelector(
        selectShouldOpenDetectionSettingsDialog,
    );

    const handleToggleProjectPanelMinimize = () => {
        dispatch(toggleProjectPanelMinimize());
    };

    const handleDeleteOnClick = () => {
        if (currentModel) {
            setModelToDelete(currentModel);
        }
    };

    const handleDeleteConfirm = () => {
        if (modelToDelete) {
            dispatch(deleteModelInCurrentProject(modelToDelete));
            syncProject({ description: getDeleteModelDescription(modelToDelete.name as string) });
            navigateToModelUrl(undefined);
        }
        setModelToDelete(null);
    };

    const handleRenameOnClick = () => {
        setIsRenaming(true);
        setNewName(currentModel?.name ?? '');
        setAnchorEl(null);
    };

    const handleRenameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
    };

    const handleConfirmRename = () => {
        dispatch(setCurrentModelName(newName.trim()));
        syncProject({
            description: getRenameModelDescription(currentModel?.name as string, newName.trim()),
        });

        setNewName('');
        setIsRenaming(false);
    };

    const handleCancelRename = () => {
        setNewName('');
        setIsRenaming(false);
    };

    const handleRenameOnKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !shouldDisableRenameConfirm) {
            handleConfirmRename();
        } else if (event.key === 'Escape') {
            handleCancelRename();
        }
    };

    const closeModel = () => {
        navigateToModelUrl(undefined);
        dispatch(setModelViewTabSelection(ModelViewTabSelection.Triangulated));
    };

    const handleOpenDetectionSettings = () => {
        const detectionSettings = detectionSettingsFromAnalyticalModelSettings(modelSettings!);
        dispatch(setAllDetectionSettings(detectionSettings));
        dispatch(openDetectionSettingsDialog());
    };

    const handleCloseDetectionSettings = () => {
        dispatch(closeDetectionSettingsDialog());
    };

    return (
        <Box p={2}>
            <Stack sx={{ justifyContent: 'space-between' }} direction="row">
                {isRenaming ? (
                    <Stack
                        sx={(theme) => ({
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            width: '100%',
                            p: theme.spacing(1, 1, 1, 0),
                        })}
                        direction="row"
                    >
                        <TextField
                            automation-id={applyTrace('rename-text-field')}
                            size="small"
                            variant="standard"
                            value={newName}
                            onChange={handleRenameOnChange}
                            onKeyUp={handleRenameOnKeyUp}
                            InputProps={{ sx: { fontSize: '12px' } }}
                            error={isNewNameInvalid}
                            helperText={isNewNameInvalid ? INVALID_NEW_MODEL_NAME_MESSAGE : null}
                        />
                        <Stack direction="row">
                            <IconButton
                                automation-id={applyTrace('rename-confirm-button')}
                                sx={{ pr: 1 }}
                                size="small"
                                disabled={shouldDisableRenameConfirm}
                                onClick={handleConfirmRename}
                            >
                                <CheckIcon
                                    color={shouldDisableRenameConfirm ? 'disabled' : 'primary'}
                                />
                            </IconButton>
                            <IconButton
                                automation-id={applyTrace('rename-cancel-button')}
                                sx={{ padding: 0 }}
                                size="small"
                                onClick={handleCancelRename}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack
                        sx={(theme) => ({
                            alignItems: 'center',
                            width: '100%',
                            p: theme.spacing(1, 1, 0, 0),
                        })}
                        direction="row"
                    >
                        <IconButton sx={{ padding: 0, mr: 1 }} size="small" onClick={closeModel}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Box>
                            <Typography>{currentModel?.name}</Typography>
                            <Typography variant="caption">{currentProject.name}</Typography>
                        </Box>
                    </Stack>
                )}
                {!isRenaming && (
                    <Stack direction="row">
                        <IconButton
                            automation-id={applyTrace('menu-button')}
                            sx={{ padding: 0 }}
                            size="small"
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                            }}
                        >
                            <MenuIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            sx={{ padding: 0 }}
                            size="small"
                            onClick={handleToggleProjectPanelMinimize}
                        >
                            {shouldMinimizeProjectPanel ? (
                                <SidebarRight fontSize="small" color="primary" />
                            ) : (
                                <SidebarLeft fontSize="small" color="primary" />
                            )}
                        </IconButton>
                    </Stack>
                )}
            </Stack>
            <PanelItemMenu
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                onDelete={handleDeleteOnClick}
                onRename={handleRenameOnClick}
                customEntries={[
                    {
                        label: DETECTION_SETTINGS_PANEL_TITLE,
                        onClick: handleOpenDetectionSettings,
                        Icon: TuneOutlined,
                    },
                ]}
            />
            <DeleteDialog
                title={DELETE_MODEL_TITLE}
                shouldOpen={Boolean(modelToDelete)}
                message={getDeleteModelMessage(modelToDelete?.name ?? '')}
                handleCancel={() => {
                    setModelToDelete(null);
                }}
                handleDelete={handleDeleteConfirm}
            />
            <DetectionSettingsPanel
                modelIndex={modelIndex}
                open={shouldOpenDetectionSettingsDialog}
                onClose={handleCloseDetectionSettings}
            />
        </Box>
    );
};

const ModelPanel = () => {
    const applyTrace = useTrace('model-panel');
    const dispatch = useAppDispatch();
    const modelObjects = useAppSelector(selectCurrentModelObjects);
    const { clearGtmObjectsAndScene } = useSceneObjectDataManager();
    const tabSelection = useAppSelector(selectModelViewTabSelection);
    const { renderParametricObjects, renderTriangulatedObjects } = useConglomerateActionManager();
    const currentModel = useAppSelector(selectCurrentModel);
    const { parametricObjectsCount, triangulatedObjectsCount } = useMemo(() => {
        if (!currentModel) {
            return { parametricObjectsCount: 0, triangulatedObjectsCount: 0 };
        }

        return {
            triangulatedObjectsCount: (currentModel as GtmAnalyticalModel).objects?.length ?? 0,
            parametricObjectsCount: (currentModel as GtmAnalyticalModel).volumes?.length ?? 0,
        };
    }, [currentModel]);

    if (!currentModel || modelObjects.length === 0) {
        return null;
    }

    const handleTabSelection = (_: unknown, newValue: ModelViewTabSelection) => {
        dispatch(setModelViewTabSelection(newValue));
        dispatch(deselectSelectedObject());
        clearGtmObjectsAndScene();

        if (newValue === ModelViewTabSelection.Parametric) {
            renderParametricObjects(currentModel as GtmAnalyticalModel);
        } else if (newValue === ModelViewTabSelection.Triangulated) {
            renderTriangulatedObjects(currentModel as GtmAnalyticalModel);
        }
    };

    return (
        <>
            <Stack
                automation-id={applyTrace()}
                sx={(theme) => ({
                    borderBottom: `${theme.palette.divider} 1px solid`,
                    justifyContent: 'space-between',
                })}
                direction="row"
            >
                <Tabs value={tabSelection} onChange={handleTabSelection}>
                    <Tab
                        sx={{ padding: 1.5 }}
                        label={
                            <Stack direction="row" spacing={1} alignItems="center">
                                <TriangulatedIcon fontSize="small" />
                                <Typography
                                    variant="button"
                                    sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                                >
                                    {getTriangulatedObjectsTabLabel(triangulatedObjectsCount)}
                                </Typography>
                            </Stack>
                        }
                        value={ModelViewTabSelection.Triangulated}
                    />
                    <Tab
                        sx={{ padding: 1.5 }}
                        label={
                            <Stack direction="row" spacing={1} alignItems="center">
                                <ParametricIcon fontSize="small" />
                                <Typography
                                    variant="button"
                                    sx={{ textTransform: 'capitalize', fontSize: '12px' }}
                                >
                                    {getParametricObjectsTabLabel(parametricObjectsCount)}
                                </Typography>
                            </Stack>
                        }
                        value={ModelViewTabSelection.Parametric}
                    />
                </Tabs>
            </Stack>
            <Box m={(theme) => theme.spacing(2, 2, 0, 2)}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textTransform: 'uppercase' }}
                >
                    {OBJECTS_LABEL}
                </Typography>
            </Box>
            {tabSelection === ModelViewTabSelection.Triangulated && <TriangulatedObjectsList />}
            {tabSelection === ModelViewTabSelection.Parametric && <ParametricObjectsList />}
        </>
    );
};

const TriangulatedObjectsList = () => {
    const currentAggregateObject = useAppSelector(selectCurrentAggregateGeometry);
    const modelObjects = useAppSelector(selectCurrentModelObjects);

    return (
        <>
            <Box p={2}>
                <Box
                    sx={(theme) => ({
                        maxHeight: DEFAULT_LIST_MAX_HEIGHT,
                        overflowY: 'auto',
                        borderRadius: theme.spacing(0.5),
                        border: 1,
                        borderColor: 'divider',
                    })}
                >
                    <List dense disablePadding>
                        {modelObjects.map((object, index) => (
                            <ModelObjectsListItem
                                key={object.id}
                                index={index}
                                object={object}
                                objectType={ObjectType.AggregatableObject}
                                isLastItem={index === modelObjects.length - 1}
                            />
                        ))}
                    </List>
                </Box>
            </Box>
            {currentAggregateObject && (
                <Box p={2} pt={0}>
                    <Box
                        sx={(theme) => ({
                            maxHeight: DEFAULT_LIST_MAX_HEIGHT,
                            overflowY: 'auto',
                            borderRadius: theme.spacing(0.5),
                            border: 1,
                            borderColor: 'divider',
                        })}
                    >
                        <AggregateModelObject
                            aggregateObject={currentAggregateObject}
                            isAnyObjectAggregated={(modelObjects as AggregatableObject[]).some(
                                (obj) => obj.isAggregated,
                            )}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

const ParametricObjectsList = () => {
    const dispatch = useAppDispatch();
    const volumes = useAppSelector(selectCurrentProjectVolumes);

    const handlePublishOnClick = () => {
        dispatch(setShouldShowPublishPanel(true));
        dispatch(setShouldShowProjectPanel(false));
    };

    return (
        <Box p={2}>
            <Box
                sx={(theme) => ({
                    maxHeight: DEFAULT_LIST_MAX_HEIGHT,
                    overflowY: 'auto',
                    borderRadius: theme.spacing(0.5),
                    border: 1,
                    borderColor: 'divider',
                })}
            >
                <List dense disablePadding>
                    {volumes.map((object, index) => (
                        <VolumeListItem
                            objectType={ObjectType.Volume}
                            key={object.id}
                            object={object}
                            isLastItem={index === volumes.length - 1}
                        />
                    ))}
                </List>
            </Box>
            {volumes.length > 0 && (
                <Button
                    sx={(theme) => ({ margin: theme.spacing(2, 0, 1, 0), width: '100%' })}
                    variant="contained"
                    size="small"
                    onClick={handlePublishOnClick}
                >
                    {PUBLISH_TO_EVO_LABEL}
                </Button>
            )}
        </Box>
    );
};

function AggregateModelObject({
    aggregateObject,
    isAnyObjectAggregated,
}: Readonly<{
    aggregateObject: GtmEvoOutputObject;
    isAnyObjectAggregated: boolean;
}>) {
    const dispatch = useAppDispatch();
    const { isObjectOnPlotByObjectId, removeHighlightFromHighlightedObject, highlightSceneObject } =
        useSceneObjectDataManager();
    const { getZoomToViewTool } = useBaseXyz();
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const { onObjectControlSelection, onObjectDeselect } = useSceneObjectSelectionManager();
    const { computeVolumes, volumesComputationStatus } = useVolumesManager();
    const currentVolumes = useAppSelector(selectCurrentProjectVolumes);
    const sceneObject = useAppSelector(sceneObjectById(aggregateObject.id));
    const currentParameterizedVolumes = useAppSelector(selectCurrentProjectParameterizedVolumes);
    const { computeParameterizedVolumes } = useParameterizedVolumesManager();

    useEffect(() => {
        if (currentVolumes.length === 0) {
            computeVolumes(aggregateObject);
        }
        if (currentParameterizedVolumes === undefined) {
            computeParameterizedVolumes(aggregateObject);
        }
    }, [currentVolumes]);

    const handleOnClick = (event: MouseEvent) => {
        if (currentModelSelectedObject?.id === aggregateObject.id) {
            dispatch(deselectSelectedObject());
            onObjectDeselect(aggregateObject.id);
            removeHighlightFromHighlightedObject();
        } else {
            onObjectControlSelection([], 0, aggregateObject.id, event);
            dispatch(setCurrentModelAggregateObjectAsSelected());
            if (isObjectOnPlotByObjectId(aggregateObject.id)) {
                getZoomToViewTool().zoomToView(aggregateObject.id);
                if (sceneObject) {
                    highlightSceneObject(sceneObject);
                }
            }
        }
    };

    return (
        <ListItem disableGutters disablePadding divider>
            <ListItemButton
                selected={currentModelSelectedObject?.id === aggregateObject.id}
                sx={(theme) => ({ padding: theme.spacing(0, 1) })}
                onClick={handleOnClick}
            >
                <HideShowButtons object={aggregateObject} objectType={ObjectType.Aggregate} />
                <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ height: '16px', margin: 'auto 0' }}
                />
                <ModelObjectName sx={{ paddingLeft: 1 }} object={aggregateObject} />
                {volumesComputationStatus === TransformationStatus.Transforming && (
                    <CircularProgress size={16} />
                )}
                {isAnyObjectAggregated && <ResetAggregateControl />}
            </ListItemButton>
        </ListItem>
    );
}

function ModelObjectName({
    object,
    sx = [],
}: Readonly<{ object: GtmEvoOutputObject | GtmProjectInput; sx?: SxProps<Theme> }>) {
    const doesObjectHaveIssues = useSelector(selectDoesCurrentModelObjectHaveIssues(object.id));

    return (
        <ListItemText
            disableTypography
            primary={
                <Stack direction="row" spacing={1.25} paddingRight="5px">
                    <OverflowTooltipTypography
                        variant="body2"
                        sx={[
                            {
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                            ...(Array.isArray(sx) ? sx : [sx]),
                        ]}
                    >
                        {fileNameExtensionRemover(object.name)}
                    </OverflowTooltipTypography>
                    {doesObjectHaveIssues && (
                        // Note the space child is not a mistake, it's so that the fallback avatar is not used
                        <Avatar
                            sx={(theme) => ({
                                bgcolor: theme.palette.warning.light,
                                height: theme.spacing(1),
                                width: theme.spacing(1),
                                color: theme.palette.common.black,
                                transform: `translate(0px, -${theme.spacing(1)}px)`,
                                marginRight: theme.spacing(1),
                            })}
                        >
                            {' '}
                        </Avatar>
                    )}
                </Stack>
            }
        />
    );
}

function ModelObjectsListItem({
    object,
    index,
    objectType,
    isLastItem,
}: Readonly<{
    object: GtmProjectInput | AggregatableObject;
    index: number;
    objectType: ObjectType;
    isLastItem?: boolean;
}>) {
    const dispatch = useAppDispatch();
    const {
        removeGtmObject,
        isObjectOnPlotByObjectId,
        highlightSceneObject,
        removeHighlightFromHighlightedObject,
    } = useSceneObjectDataManager();
    const selectedObjectIndex = useAppSelector(selectSelectedObjectIndex);
    const { getZoomToViewTool } = useBaseXyz();
    const { syncProject } = useProjectSynchronizer();
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const projectData = useAppSelector(selectCurrentProjectData);
    const { onObjectControlSelection, onObjectDeselect } = useSceneObjectSelectionManager();
    const sceneObject = useAppSelector(sceneObjectById(object.id));

    const isAnalyticalModel = isGtmAnalyticalModel(projectData.models?.[selectedModelIndex]);
    const isAggregated =
        isAnalyticalModel &&
        (projectData.models[selectedModelIndex] as GtmAnalyticalModel).objects?.[index]
            ?.isAggregated;

    const handleOnClick = (event: MouseEvent) => {
        if (selectedObjectIndex === index) {
            dispatch(deselectSelectedObject());
            onObjectDeselect(object.id);
            removeHighlightFromHighlightedObject();
        } else {
            // TODO: Handle multiselect
            onObjectControlSelection([], index, object.id, event);
            dispatch(setSelectedObjectIndex(index));
            if (isObjectOnPlotByObjectId(object.id)) {
                if (sceneObject) {
                    highlightSceneObject(sceneObject);
                }
                getZoomToViewTool().zoomToView(object.id);
            }
        }
    };

    return (
        <ListItem disableGutters disablePadding divider={!isLastItem}>
            <ListItemButton
                selected={selectedObjectIndex === index}
                sx={(theme) => ({ padding: theme.spacing(0.5, 1) })}
                onClick={handleOnClick}
            >
                <HideShowButtons object={object} objectType={objectType} />
                <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ height: '16px', margin: 'auto 0' }}
                />
                <MeshIcon fontSize="small" sx={{ marginLeft: 1 }} />
                <ModelObjectName sx={{ paddingLeft: 1 }} object={object} />
                {isAnalyticalModel && !isAggregated && (
                    <AggregateControl
                        handleAggregateObject={() => {
                            // Hide the input surface once it is aggregated
                            const updatedObject = {
                                ...object,
                                visible: false,
                                isAggregated: true,
                            };
                            dispatch(updateAnalyticalModelObject([object.id, updatedObject]));
                            syncProject(skipHistoryEntry);
                            removeGtmObject(object.id);
                        }}
                        inputMesh={object}
                    />
                )}
            </ListItemButton>
        </ListItem>
    );
}

function VolumeListItem({
    object,
    objectType,
    isLastItem,
}: Readonly<{
    object: GtmProjectInput | AggregatableObject;
    objectType: ObjectType;
    isLastItem?: boolean;
}>) {
    const { isObjectOnPlotByObjectId } = useSceneObjectDataManager();
    const { getZoomToViewTool } = useBaseXyz();

    const handleOnClick = () => {
        if (isObjectOnPlotByObjectId(object.id)) {
            getZoomToViewTool().zoomToView(object.id);
        }
    };

    return (
        <ListItem disableGutters disablePadding divider={!isLastItem}>
            <ListItemButton
                sx={(theme) => ({ padding: theme.spacing(0.5, 1) })}
                onClick={handleOnClick}
            >
                <HideShowButtons object={object} objectType={objectType} />
                <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ height: '16px', margin: 'auto 0' }}
                />
                <VolumeIcon fontSize="small" sx={{ marginLeft: 1 }} />
                <ModelObjectName sx={{ paddingLeft: 1 }} object={object} />
            </ListItemButton>
        </ListItem>
    );
}
